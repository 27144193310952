<template>
  <div>
    <div class="row">
      <div class="col-4">
        <img :src="newsArticle.image" alt="" class="w-100" />
      </div>

      <div class="col-8">
        <span class="fw-bold fs-4">
          {{ newsArticle.title }}
        </span>
        <br />
        {{ newsArticle.category }}
        <br />
        {{ newsArticle.publicationDate }}
      </div>
      <div class="col-12">
        <p v-html="newsArticle.content"></p>
      </div>
    </div>

    <br />

    <div class="row text-end">
      <div class="col-8">
        <span class="fw-bold fs-4">
          {{ newsArticle.titleAr }}
        </span>
        <br />
        {{ newsArticle.category }}
        <br />
        {{ newsArticle.publicationDate }}
      </div>
      <div class="col-4">
        <img :src="newsArticle.image" alt="" class="w-100" />
      </div>

      <div class="col-12">
        <p v-html="newsArticle.contentAr"></p>
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col-auto">
        <button
          @click="
            $router.push({
              name: 'news-edit',
              params: {
                id: newsArticle.slug,
              },
            })
          "
          class="btn btn-warning"
        >
          Modifier
        </button>
      </div>
      <div class="col-auto">
        <button class="btn btn-danger">Supprimer</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("news/find", this.$route.params.id);
  },
  computed: {
    ...mapGetters("news", { newsArticle: "getNewsArticle" }),
  },
};
</script>

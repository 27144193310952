var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.newsArticle.image,"alt":""}})]),_c('div',{staticClass:"col-8"},[_c('span',{staticClass:"fw-bold fs-4"},[_vm._v(" "+_vm._s(_vm.newsArticle.title)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.newsArticle.category)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.newsArticle.publicationDate)+" ")]),_c('div',{staticClass:"col-12"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.newsArticle.content)}})])]),_c('br'),_c('div',{staticClass:"row text-end"},[_c('div',{staticClass:"col-8"},[_c('span',{staticClass:"fw-bold fs-4"},[_vm._v(" "+_vm._s(_vm.newsArticle.titleAr)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.newsArticle.category)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.newsArticle.publicationDate)+" ")]),_c('div',{staticClass:"col-4"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.newsArticle.image,"alt":""}})]),_c('div',{staticClass:"col-12"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.newsArticle.contentAr)}})])]),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.$router.push({
            name: 'news-edit',
            params: {
              id: _vm.newsArticle.slug,
            },
          })}}},[_vm._v(" Modifier ")])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-danger"},[_vm._v("Supprimer")])])
}]

export { render, staticRenderFns }